<template>
  <div>
    <div class="mb-4">
      <h3>{{ !categorySelected ? $t('purchase.catalogue.title') : categorySelected }}</h3>
      <div v-if="$route.query.search_query" class="">
        Hasil pencarian untuk <strong>"{{ $route.query.search_query }}"</strong>
      </div>
    </div>

    <a-row class="category-selected" v-if="categorySelected && categories.length">
      <a-col style="cursor: pointer" :span="12" class="all" @click.prevent="() => handleSelectCategory()">
        {{ $t('purchase.catalogue.all_product') }}
      </a-col>
      <a-col :span="12" class="search">
        <a-input-search :default-value="$route.query.search_query" :placeholder="$t('form.placeholder_search_product')"
          @change="handleSearch($event.target.value)" style="width: 100%" />
      </a-col>
    </a-row>

    <div v-if="!products.length && !fetching" class="my-4">
      <div class="mx-auto">
        {{ $t('purchase.catalogue.product_not_found') }}
      </div>
    </div>

    <div class="product-wrapper">
      <div class="product-grid-list">
        <div v-for="(product, index) in products" :key="index" class="product-grid-list__item">
          <CatalogueListItem :product="product" :prices="prices" :list_product="products"
            :dataDiscount="dataDiscount" />
        </div>
      </div>
      <div v-if="total > perPage" class="mt-5">
        <a-pagination :default-current="+$route.query.page" :total="total" :page-size="perPage"
          class="mx-auto d-flex justify-content-center"
          @change="(page) => $router.push({ query: { ...$route.query, page } })" v-if="!loading && products.length" />
      </div>
    </div>
  </div>
</template>

<script>
// import apiClient from '@/services/axios'
import { mapState } from 'vuex'
// import debounce from 'lodash/debounce'
// import 'slick-carousel/slick/slick.css'
import CatalogueListItem from './CatalogueListItem.vue'

const sort = [
  {
    label: 'Product Name [A-Z]',
    value: ['product_name', 'asc'],
  },
  {
    label: 'Product Name [Z-A]',
    value: ['product_name', 'desc'],
  },
]

export default {
  components: {
    CatalogueListItem,
  },
  data() {
    return {
      fetching: true,
      products: [],
      perPage: 35,
      total: 0,
      categories: [],
      prices: [],
      categorySelected: null,
      warehouse_id: null,
      listCatalogId: [],
      dataDiscount: [],
    }
  },
  computed: {
    ...mapState('user', {
      channelId: (state) => state.channel_id,
    }),
    loading() {
      return this.$store.state.purchase.loading
    },
    /**
     * @returns {object[]}
     */
    sortItems() {
      return sort
    },
    /**
     * @returns {string}
     */
    selectedSort() {
      return this.$route.query.sort && this.$route.query.order
        ? [this.$route.query.sort, this.$route.query.order].join('__')
        : undefined
    },
  },
  watch: {
    '$route.query'() {
      this.fetchWarehouseId()
      if (this.$route.query.category) {
        const findCategory = this.categories.find(
          (item) => +item.id === +this.$route.query.category,
        )

        this.categorySelected = findCategory.name
      } else {
        this.categorySelected = null
      }
    },
  },
  created() {
    // this.debouncedQueryChanged = debounce((val) => {
    //   this.$router.push({
    //     path: '/purchase/catalogue',
    //     query: { ...this.$route.query, search_query: val || undefined },
    //   })
    // }, 250)
  },
  mounted() {
    this.fetchWarehouseId()
    this.fetchProducCategories()
  },
  methods: {
    async fetchWarehouseId() {
      if (this.$store.state.user.user_data.seller) {
        const whsList = this.$store.state.user.user_data.seller.map(
          (item) => item.warehouse_id,
        )
        this.fetchProducts(whsList)
      } else {
        this.$store
          .dispatch('warehouse/GETWAREHOUSE_COVERAGE', {
            channel_id: this.$store.state.user.user_data.channel_id,
            area_id: this.$store.state.user.user_data.area_id,
          })
          .then(({ data }) => {
            this.fetchProducts(data.map((item) => item.warehouse_id))
          })
      }
    },
    async fetchProducts(warehouse_id) {
      this.fetching = true
      const warehouse_query =
        warehouse_id.length > 1
          ? `warehouse_id=${warehouse_id.join('&warehouse_id=')}`
          : `warehouse_id=${warehouse_id[0]}`
      this.$store
        .dispatch('product/GETPRODUCTLIST', {
          channel_id: this.$store.state.user.user_data.channel_id,
          limit: this.perPage,
          warehouse_query: warehouse_query,
          ...this.$route.query,
        })
        .then(async ({ data, total_row }) => {
          const products = data || []
          this.listCatalogId = products.map(v => { return v.catalogs[0].id })
          if (this.listCatalogId.length > 0) {
            await this.fetchFlagDiscount()
          }
          const product_id = products.map((item) => item.id)
          this.products = products
          this.total = total_row
          let defaultPriceAreaId = this.$store.state.user.user_data.price_area_id
          const isNOO = Boolean(!this.$store.state.user.user_data.seller?.length)
          if (!isNOO) {
            defaultPriceAreaId = this.$store.state.user.user_data.seller.map((obj) => obj.price_area_id).join(',')
          }
          return this.$store.dispatch('price/GETPRICEPRODUCT', {
            id: product_id,
            channel_id: this.$store.state.user.user_data.channel_id,
            price_area_id: defaultPriceAreaId,
            customer_category_id: this.$store.state.user.user_data.customer_category_id,
            limit: this.perPage,
          })
        })
        .then(({ data }) => {
          this.prices = data
          this.fetching = false
        })
    },
    async fetchFlagDiscount() {
      this.$store
        .dispatch('product/GETDISCOUNT', {
          channel_id: this.$store.state.app.channel_id,
          business_id: this.$store.state.app.business_id,
          catalog_ids: this.listCatalogId.toString(),
          customer_category_id: this.$store.state.user.user_data.customer_category_id,
        })
        .then((response) => {
          this.dataDiscount = response.data
        })
    },
    async fetchProducCategories() {
      this.$store
        .dispatch('product/GETPRODUCTCATEGORIES', {
          channel_id: this.$store.state.user.channel_id,
        })
        .then((response) => {
          this.categories = response
          if (this.$route.query.category) {
            const findCategory = response.find(
              (item) => +item.id === +this.$route.query.category,
            )

            this.categorySelected = findCategory.name
          } else {
            this.categorySelected = null
          }
        })
    },
    onSortSelectChange(value) {
      const [sort, order] = value.split('__')
      this.$router.push({
        query: {
          ...this.$route.query,
          sort,
          order,
        },
      })
    },
    getImageCategory(name) {
      switch (name.toLowerCase()) {
        case 'personal care':
          return 'https://ik.imagekit.io/fepca/Group_2618_WU6T0KDcz.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654826893652'

        case 'household':
          return 'https://ik.imagekit.io/fepca/Group_2619_AInFPjQum.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654826893718'

        case 'food & beverages':
          return 'https://ik.imagekit.io/fepca/Group_2620_A_h8JPtPi.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654826895004'

        case 'pharmaceutical':
          return 'https://ik.imagekit.io/fepca/Group_2621_mpezEqu-h.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654826895539'

        case 'pet food':
          return 'https://ik.imagekit.io/fepca/Group_2622_e5kvziEH0.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654826896281'

        default:
          return ''
      }
    },
    handleSelectCategory(id) {
      if (!id) {
        this.categorySelected = null
        this.$router.push({
          path: '/purchase/catalogue',
          query: { page: 1, limit: this.perPage },
        })
      } else {
        const findCategory = this.categories.find((item) => item.id === id)

        this.categorySelected = findCategory.name

        this.$router.push({
          path: '/purchase/catalogue',
          query: { ...this.$router.query, page: 1, category: id },
        })
      }
    },
    getCategoryName(category) {
      const vendor_name = this.$store.state.app.vendor_name

      if (vendor_name.toLowerCase() === 'kino') {
        switch (category.name.toLowerCase()) {
          case 'house hold':
            return 'Household'

          case 'food & beverage':
            return 'food & beverages'

          case 'food beverages':
            return 'food & beverages'

          default:
            return category.name
        }
      }

      return category.name
    },
    handleSearch(value) {
      if (value.length >= 3 || !value) {
        setTimeout(() => {
          this.$router.push({
            path: '/purchase/catalogue',
            query: { ...this.$route.query, search_query: value || undefined },
          })
        }, 250)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.product {
  &-filter {
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
  }

  &-category {
    background: #fff;
    color: #1a1a1a;
    border-radius: 8px;
    padding: 1.5rem;
    width: auto;
    height: auto;

    &__item {
      cursor: pointer;

      img {
        width: 75.82px;
        height: 81.67px;

        @media (max-width: 567px) {
          width: 37px !important;
          height: 45px !important;
        }
      }

      &-title {
        text-transform: uppercase;
        color: #929292;

        @media (max-width: 567px) {
          font-size: 8px !important;
        }
      }
    }

    // &__title {
    //   text-transform: uppercase;
    //   font-style: normal;
    //   font-weight: bold;
    //   font-size: 16px;
    //   line-height: 19px;
    //   color: #000000;
    // }

    // &__item {
    //   width: 155.82px;
    //   height: 161.67px;
    //   left: 590px;
    //   top: 62px;
    //   // background: #FFFFFF;
    //   // box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    //   border-radius: 5px;
    //   margin: 0 1.5rem !important;

    //   &-title {
    //     text-transform: uppercase;
    //     color: #929292;
    //   }
    // }

    // @media (max-width: 567px) {
    //   &__item {
    //     margin: .5rem !important;
    //     // width: 105.82px;
    //     // height: 111.67px;
    //     width: 25px;
    //     height: auto;

    //     img {
    //       height: 10px;
    //       width: auto;
    //     }

    //     &-title {
    //       font-size: 2px;
    //     }
    //   }

    // }
  }
}

.product-grid-list {
  @include media-breakpoint-up(lg) {
    gap: 2rem;
  }
}

.product-section-category {
  padding: 0 98px;

  @media (max-width: 567px) {
    padding: 0;
  }
}

.product-content {
  justify-content: center;

  @media (max-width: 600px) {
    justify-content: space-between;
  }
}

.catalog-icon {
  background: #fff !important;
  padding: 7px 12px;
  border-radius: 50%;
}

.category-selected {
  height: 51px;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 3rem 0;

  @media (max-width: 600px) {
    margin: 1rem 0;
  }

  .all {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #000000;
  }
}

.card_kategori {
  background: #fff;
  border-radius: 6px;
  width: 100%;
  flex: 1 245px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:last-child {
    margin-right: 0;
  }

  img {
    width: 100%;
    margin: auto;
    cursor: pointer;
  }

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 0;
    text-align: center;
    letter-spacing: 0.05em;
    color: #929292;
    text-transform: uppercase;
  }
}

.swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: all 0.2s ease-in;
}

.prev-arrow {
  position: relative;
  top: 75px;
  z-index: 3;
  width: 35px;
}

.next-arrow {
  position: relative;
  top: 75px;
  z-index: 3;
  width: 35px;
}

// Banner Slider
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: auto;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
</style>
