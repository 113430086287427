<template>
  <div class="cui__uncontent">
    <div class="biz-container">
      <CatalogueList />
    </div>
  </div>
</template>

<script>
import CatalogueList from '@/components/Purchase/Catalogue/CatalogueList.vue'

export default {
  name: 'purchase.catalogue',
  components: { CatalogueList },
}
</script>
